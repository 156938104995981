/* ListLeadComponent.module.css */
.leadsContainer {
    /* padding: 1rem; */
    margin-left: 220px;
    margin-top: -30px;
    padding: 20px;
}
.leadsContainerSmallNav{
    margin-left: 80px;
    margin-top: -30px;
    padding: 20px;
}

.viewTaskContainer {
    /* padding: 1rem; */
    margin-left: 220px;
    margin-top: -30px;
    padding: 20px;
}
.viewTaskContainerSmall{
    margin-left: 80px;
    margin-top: -30px;
    padding: 20px;
}


.leadInfo {
    max-width: 800px;
    margin: 0 auto;
}

.skeletonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.skeletonCard {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skeletonHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.skeletonBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e9ecef;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.cardTitle {
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.statusBadge {
    background-color: #28a745; /* Green for success */
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
}

.cardBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.col {
    flex: 1;
    min-width: 200px;
}

p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

strong {
    color: #333;
}
