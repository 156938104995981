@media (min-width: 992px) {
  .navbar-expand-lg {
    z-index: 999;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 1.2rem;
    margin-left: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.innerpages-nav .navbar {
  z-index: 100;
  background-color: #f5f2f2;
  box-shadow: 0px 0px 5px #201f1f;
}

.home-nav .navbar {
  background-color: #fff;
  box-shadow: 0px 0px 5px #eeebeb;
}
.namelink {
  font-size: 16px;
  color: #34d0e7;
  cursor: pointer;
}
.namelink:hover {
  color: #2b5797;
}
.logo-img {
  width: 150px;
  height: 60px;
}
.footer-logo-img{
  width: 70px;
  height: 70px;
}
.login-btn {
  color: #ffffff !important;
  /*font-weight: 600;*/
  background-color: #540326 !important;
  border: 2px solid #540326 !important;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 6px;
}
.login-btn-home {
  color: #ffffff;
  /*font-weight: 600;*/
  background-color: #540326;
  border: 2px solid #540326;
  padding: 0.5rem 0.7rem;
  font-size: 0.8rem;
  border-radius: 6px;
}

.validationmsg {
  color: red;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 5px;
}

.delete-btn {
  color: #ffffff;
  /*font-weight: 600;*/
  background-color: #f52e38;
  border: 2px solid #f52e38;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 6px;
}
.login-btn:hover,
.login-btn.active {
  color: #ffffff;
  background-color: #4e2427;
  border-color: #4e2427;
}
.white-btn {
  color: #540326;
  /*font-weight: 700;*/
  background-color: #ffffff;
  border: 2px solid #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}
.home-nav .navbar-light .navbar-nav .nav-link {
  color: rgb(0 0 0);
  /*font-weight: 700;*/
  font-size: 15px;
  border-top: 4px solid #ffffff00;
  padding: 0.1rem 0.1rem;
}
.home-nav .navbar-light .navbar-nav .nav-link.active,
.home-nav .navbar-light .navbar-nav .nav-link:hover {
  color: #703a3e;
  border-top: 4px solid #703a3e;
  border-radius: 3px;
}
.topbar-bg {
  background-color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.topbar-bg li {
  color: #fff;
}
.topbar-bg li a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.filled-icon {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.sticky-nav {
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px #eeebeb;
}

/* footer  start */
.footer {
  background: #fcefde;
  color: black;
}
.footer .links ul {
  list-style-type: none;
}
.footer .links ul li {
  margin-bottom: 7px;
}

.footer .links li a {
  color: rgb(0, 0, 0);
  transition: color 0.2s;
  text-decoration: none;
  font-size: 15px;
  /*font-weight: 600;*/
}
.footer .location p {
  font-size: 15px;
  /*font-weight: 600;*/
  cursor: pointer;
}
.footer .links li a:hover {
  text-decoration: none;
  color: #703a3e;
  /*font-weight: 700;*/
}
.footer .about-company i {
  font-size: 25px;
}
.footer .about-company a {
  color: rgb(0, 0, 0);
  transition: color 0.2s;
}
.footer .about-company a:hover {
  color: 4px solid #703a3e;
}
.footer .location i {
  font-size: 18px;
}
.footer .copyright p {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.f-heading {
  /*font-weight: 700;*/
  margin-bottom: 20px;
  cursor: pointer;
}

/* footer end */

/* home banner start*/
.banner-heading {
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /*font-weight: 700;*/
  text-transform: uppercase;
}
.home-banner {
  background-color: #fffbf6;
  margin-top: 0px;
}

.small-banner-img-head {
  position: relative;
}
.small-banner-img-content {
  position: absolute;
  top: 20%;
  text-align: center;
  color: #fff;
  /*font-weight: 700;*/
  margin: auto;
  left: 0;
  right: 0;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background-color: #0000002b;
}
.text h6 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 35%;
  font-size: 22px;
  left: 45%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.number-img {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100px !important;
  height: 65px;
  z-index: 1;
  opacity: 0.6;
}
/* home banner end */

/* about banner start */
.about-banner {
  background-image: url("../Assets/Images/about-banner.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  height: auto;
  width: 100%;
}
.about-banner2 {
  background-image: url("../Assets/Images/about-banner2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: auto;
  width: 100%;
}
.after-line:after {
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  content: "";
  display: inline-block;
  height: 3.7px;
  position: relative;
  vertical-align: middle;
  width: 5%;
  left: 0.1em;
}
.si-icon {
  width: 40px !important;
  height: 40px !important;
}
.ser-section {
  background-color: #faf9f9;
  margin: -40% 7% 7%;
  padding: 7%;
  z-index: 100;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.ps-rel {
  position: relative;
}
.bs-rounded {
  border-radius: 10px;
}
.accordion-item {
  background-color: #fff;
  border: 1px solid rgb(0 0 0 / 0%);
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe00;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button {
  color: #d78837;
  /*font-weight: 700;*/
}
.accordion-button:not(.collapsed) {
  color: #d78837;
  background-color: #f2f2f2 !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
  /*font-weight: 700;*/
}
.accordion-body {
  padding: 1rem 1.25rem;
  background-color: #f2f2f2 !important;
}
.counter-content {
  text-align: center;
  margin-bottom: 5%;
}
.counter-content h1,
.counter-content h5 {
  font-weight: bold;
  color: #703a3e;
}
.number-counter {
  /* background-color: #f1ebec; */
  /* background: linear-gradient(135deg, #bf6338, #f08a5d); */
  /* background: linear-gradient(135deg, #f8d7da, #f1ebec); */
  background: linear-gradient(180deg, #c56a38 0%, #de9338 100%);
  color: #333; /* Adjust text color for better contrast */
}
.border-right,
.border-right2 {
  border-right: 4px solid #703a3e;
}
.client-section {
  padding: 5%;
  background-color: #ebebeb;
  margin: 3%;
  border-radius: 8px;
  min-height: 200px;
  max-height: 200px;
}
.client-img {
  border: 5px solid #fff;
  border-radius: 50px;
}
.client-left-section {
  background-color: #f5f5f5;
  padding: 6%;
  height: 100%;
}
.client-right-section {
  background-color: #000000;
  padding: 4% 6%;
  color: #fff;
  width: 100%;
  background-image: url("../Assets/Images/form-bg.webp");
  background-size: contain;
  height: 100%;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #703a3e !important;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #f1f1f1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}
.client-right-section .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  /*font-weight: 400;*/
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
}
.client-right-section .input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.client-right-section .form-control {
  border: 1px solid #ffffff;
  padding: 0.7rem 1rem;
  font-size: 1rem;
}
.btn-secondary {
  color: #fff;
  background-color: #343434;
  border-color: #343434;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  /*font-weight: 600;*/
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 0%);
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0px !important;
}
/* about banner end */

/* about page start */
.aboutpage-banner {
  background-image: url("../Assets/Images/aboutpage-banner.webp");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  margin-top: 0px;
  background-repeat: no-repeat;
  position: relative;
}
.head-banner-posi {
  position: absolute;
  bottom: 40%;
  width: 50%;
}
.innerpages-nav .navbar-light .navbar-nav .nav-link {
  color: rgb(10, 10, 10);
  /*font-weight: 700;*/
  font-size: 15px;
  border-top: 4px solid #ffffff00;
  padding: 0.1rem 0.1rem;
}
.innerpages-nav .navbar-light .navbar-nav .nav-link.active,
.innerpages-nav .navbar-light .navbar-nav .nav-link:hover {
  background: linear-gradient(180deg, #c56a38 0%, #de9338 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border-top: 4px solid #de9338;
  border-radius: 3px;
}
.innerpages-nav .sticky-nav {
  background-color: #f3ecec;
}
.about-call {
  font-size: 40px;
  background-color: #703a3e;
  color: #fff;
  border-radius: 50px;
  padding: 10px;
}
.about-whatsapp-icon {
  font-size: 40px;
  background-color: #703a3e;
  color: #fff;
  border-radius: 50px;
  padding: 10px 12px;
}
.client-left-section iframe {
  height: 580px;
}
.form-control {
  /* border: 1px solid #ffffff;
  box-shadow: 1px 2px 15px rgb(0 0 0 / 6%);
  font-size: 1rem;
  padding: 0.7rem 1rem; */
  border: 1px solid #e7dada;
  /* box-shadow: 1px 2px 2px rgb(0 0 0 / 6%); */
  font-size: 1rem;
  padding: 0.7rem 1rem;
}
.form-label {
  /*font-weight: 700;*/
}
.tick-icon {
  color: #0075ff;
  cursor: pointer;
}
.legalpage-banner {
  background-image: url("../Assets/Images/legalpage-banner.webp");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  margin-top: -96px;
  background-repeat: no-repeat;
  position: relative;
}
.tab-section ul {
  display: flex;
  list-style-type: none;
  margin-bottom: 0px;
}
.LegalTab {
  padding: 14px 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  cursor: pointer;
}
.LegalTab.active {
  background-color: #f5f5f5;
  color: #540326;
  /*font-weight: 700;*/
}
.LegalTab.inactive {
  background-color: #ffffff;
  color: #540326;
  /*font-weight: 600;*/
}
.legal-img {
  border-radius: 50%;
}
.careerpage-banner {
  background-image: url("../Assets/Images/career-banner.webp");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  margin-top: 0px;
  background-repeat: no-repeat;
  position: relative;
}
.color-maroon {
  color: #540326;
}
.legalpage-content {
  background-color: #f1ebec;
  /* border-radius: 10px; */
  color: #540326;
}
.legaltab-color {
  background-color: #f1ebec;
  color: #540326;
  padding: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.legaltab-color:hover {
  background-color: #540326;
  color: #ffffff;
  cursor: pointer;
}
.jobposition-section {
  border: 1px solid #c9d7df;
  border-radius: 4px;
}
.bg-description {
  background-color: #f1ebec;
  padding: 0% 2%;
}
.bg-des-content {
  padding: 0% 2%;
  text-align: justify;
}
.bg-des-content .form-label {
  color: #000000;
}
.bg-des-content .form-control {
  border: 1px solid #909090;
}
.custom-btn input[type="file"] {
  border: 2px solid #333;
  display: none;
}
.custom-btn input[type="file"] + label {
  padding: 7px 13px;
  width: auto;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  /* border: 1px solid #ffffff; */
  background: #703a3e;
  /* font-family: "IBMPlexSans-Regular" !important; */
  color: #fff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.overflow-pages {
  overflow-x: hidden;
}
.servicespage-banner {
  background-image: url("../Assets/Images/service-banner.webp");
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  margin-top: 0px;
  background-repeat: no-repeat;
}
.contactpage-banner {
  background-image: url("../Assets/Images/contact-banner.webp");
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  margin-top: 0px;
  background-repeat: no-repeat;
}
.counter-content2 .about-call {
  font-size: 22px;
  background-color: #703a3e;
  color: #fff;
  border-radius: 50px;
  padding: 10px;
  display: block;
}
.counter-content2 .about-whatsapp-icon {
  font-size: 22px;
  background-color: #703a3e;
  color: #fff;
  border-radius: 50px;
  padding: 10px 12px;
  display: block;
}
.contact-iframe iframe {
  height: 370px;
}
.legal-img-bg {
  position: absolute;
  width: 90%;
  height: auto;
}
.auctionpage-banner {
  background-image: url("../Assets/Images/flat-auction.webp");
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  margin-top: 0px;
  background-repeat: no-repeat;
}

/* modal start */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4) !important;
  display: flex;
  justify-content: center;
  border-radius: 10px !important;
  align-items: center;
  z-index: 3000;
  height: 100vh;
  /* overflow-x: hidden; */
  padding-top: 0%;
  /* overflow-y: auto; */
}

body.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.modal-open {
  overflow: hidden;
  height: 100% !important;
}

.modal-content {
  min-height: 200px;
  background-color: #fffcfc !important;
  border-radius: 8px;
  /* padding: 25px; */
}

.modalHeader {
  background: linear-gradient(180deg, #df7239 0%, #e19937 100%);
  color: #ffffff;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.succ-failure {
  background-color: #00abad;
  color: #ffffff;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.Modal-View {
  width: 75% !important;
  /* height: 700px; */
}
.book-now-modal-width .Modal-View {
  width: 100% !important;
  /* height: 700px; */
}
.modal-footer {
  background-color: #f2f2f2;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.modal-scroll-content {
  height: 56vh;
  overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.modal-scroll-content::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.modal-scroll-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.modal-content .close-icon {
  position: absolute;
  right: -15px;
  top: -15px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid rgb(255, 255, 255);
  padding: 5px;
  cursor: pointer;
  z-index: 100;
}
.modal-content .close-icon:hover {
  border: 2px solid #000;
}
.psw-icon-section {
  position: relative;
}
.psw-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
/* modal end */

.home-banner-content .banner-heading {
  font-size: 50px;
}
.rounded-top {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}
.border-radius-about {
  border-radius: 10px;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("../Assets/Images/bottom-arrow.webp");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../Assets/Images/bottom-arrow.webp");
  transform: rotate(-180deg);
}
.home-banner h6 {
  /*font-weight: 600;*/
}
.rel-content {
  position: absolute;
  top: 40%;
  color: #fff;
  left: 15%;
}
.br-8 {
  border-radius: 10px;
}

/* ============ desktop view ============ */
.megamenu .col-megamenu ul li {
  line-height: 2;
  font-size: 14px;
}
.megamenu .col-megamenu ul li a {
  color: #323030;
  text-decoration: none;
}
.megamenu .col-megamenu ul li a:hover,
.megamenu .col-megamenu ul li a.active {
  color: #4e2427;
  /*font-weight: 700;*/
}
.tab-active {
  border: 2px solid #703a3e;
  background-color: #703a3e;
  border-radius: 4px;
  padding: 5px;
  color: #fff;
}
.tab-inactive {
  border: 2px solid #703a3e;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 4px;
}
.tab-active a {
  color: #fff;
  text-decoration: none;
}
.tab-inactive a {
  color: #703a3e;
  text-decoration: none;
}
.navbar .megamenu {
  padding: 0;
}

@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
  }
  .navbar .megamenu {
    left: 10%;
    height: 80vh;
    right: 10%;
    width: 80%;
    top: 0;
    margin-top: 0;
    background-color: #fff;
    height: auto;
  }
}
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}
/* ============ mobile view .end// ============ */

/* sidebar start */

.sidenav {
  height: 100vh;
  width: 17%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  /* background-color: #fcefde; */
  border-right: 1px solid #ccc; /* Add a right border */
}


.side-content-bg ul .material-symbols-rounded,
p .material-symbols-rounded {
  font-size: 20px;
}

.sidenav a {
  padding: 0px 0px;
  text-decoration: none;
  /* font-size: 16px; */
  color: #540326;
  display: flex;
  margin: 15px 0px;
  /*font-weight: 700;*/
  align-items: center;
}
.nav-link:focus,
.nav-link:hover {
  color: #4f5965;
}

.sidenav ul {
  padding-bottom: 0;
  padding-inline-start: 0px;
}

.sidenav ul li {
  list-style-type: none;
  font-size: 14px;
  padding-left: 5px;
  padding-top: 2px;
}

.sidenav a:hover,
.sidenav a.active {
  color: #ffffff;
  /* background-color: #540326; */
  background-color: #dc3545;
  border-radius: 0px;
  /*font-weight: 700;*/
}

.main {
  left: 17%;
  font-size: 14px;
  margin: 10px;
  height: 88vh;
  position: absolute;
  width: 81.5%;
  top: 12vh;
  background-color: #f5f5f500;
}
.sidenav a.active:before,
.sidenav a:hover:before {
  content: "";
  width: 10px;
  height: 10px;
  color: #fff;
  content: "";
  display: block;
  width: 10px;
  height: 45px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 10px;
  /* background: linear-gradient(180deg, #df7239 0%, #e19937 100%); */
}
.sidenav a:before {
  content: "";
  width: 10px;
  height: 10px;
  color: #fff;
  content: "";
  display: block;
  width: 10px;
  height: 45px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 10px;
  background: none;
}

.topbar {
  margin-left: 17%;
  top: 0px;
  width: 83%;
  position: fixed;
  height: 12vh;
  z-index: 1000;
  background-color: #fff;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.logo-bg {
  text-align: center;
  margin: 10% auto;
}
.side-content-bg {
  height: 82vh;
  position: absolute;
  top: 16vh;
  width: 100%;
  overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.side-content-bg::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.side-content-bg {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll !important;
}
.topbar-content-bg {
  padding: 10px 30px;
  color: #540326;
  border-radius: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.heading-content-bg {
  background-color: #34d0e7;

  padding: 12px 30px;
  border-radius: 0px;
}
.heading-content-bg h5 {
  color: #fff;
  font-size: 16px;
  padding: 4px;
}
/* sidebar end */

.admin .form-control {
  border: 1px solid #fcefde;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0);
  font-size: 1rem;
  padding: 0.2rem 0.5rem 0.2rem 0rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fcefde;
}
.admin .input-group-text {
  background-color: #fcefde;
  border: 1px solid #fcefde;
}
.admin .input-group-text {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #540326;
}
.admin .input-group {
  width: auto;
}
.admin .form-control::placeholder {
  color: #540326;
  opacity: 1; /* Firefox */
}

.admin .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #540326;
}

.admin .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #540326;
}
.dash-card {
  background: #540326;
  border-radius: 16px;
  padding: 10%;
  color: #fff;
  padding: 8%;
}
.dash-icon-bg {
  background: #ffffff;
  border-radius: 12px;
  width: 52px;
}
.dash-icon {
  font-size: 50px;
  background: -webkit-linear-gradient(180deg, #df7239 0%, #e19937 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  display: block;
}
.dash-card h1 {
  font-size: 45px;
}
.pi-icon {
  width: 22px;
  height: 22px;
}
.cursor {
  cursor: pointer;
}

.figure {
  position: relative;
}
.figure img {
  display: block;
  max-width: 100%;
  height: auto;
}
.figure .image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  /* transition: opacity .2s; */
}
.sidenav a:hover .image-hover,
.sidenav a.active .image-hover {
  opacity: 1;
}
.btn-admin {
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  color: #fff;
  /*font-weight: 700;*/
  border-radius: 8px;
  font-size: 14px;
}

.admin-ul {
  padding-left: 0;
}
.admin-li {
  background-color: #fcefde;
  list-style-type: none;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 8px;
  /*font-weight: 700;*/
  border-radius: 25px;
  display: inline-block;
  margin: 2px;
  font-size: 11px;
}
.cost-pack {
  background-color: #fff;
  position: absolute;
  padding: 3px 16px;
  border-radius: 6px;
  /*font-weight: 700;*/
  top: 10px;
  right: 10px;
  color: #540326;
}
.admin-service {
  padding: 5%;
  margin: 0%;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05),
    inset 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  height: 63vh;
  overflow-y: scroll;
}
.admin-service-gold {
  padding: 5%;
  margin: 0%;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05),
    inset 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  min-height: 30vh;
  overflow-y: scroll;
}
.admin-service-wheeler {
  padding: 5%;
  margin: 0%;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05),
    inset 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow-y: scroll;
  min-height: 280px;
  margin-bottom: 15px;
}
.admin-service-wheeler-buy-now {
  padding: 10% 5% 5%;
  margin: 0%;
  background: #540326;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05),
    inset 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  /* overflow-y: scroll;
  height: 580px; */
  margin-bottom: 15px;
  justify-content: space-between;
  flex-direction: column;
  /* height: 100vh; */
  display: flex;
}
.admin-service-wheeler-buy-now p {
  text-align: left;
  color: #fff;
}
.admin-service-wheeler-buy-now .btn-holder {
  justify-content: flex-end;
  display: flex;
}
.admin-service-wheeler-buy-now .btn-holder-tc {
  display: flex;
}
.admin-service2 {
  padding: 5%;
  margin: 0%;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05),
    inset 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  height: 100%;
}
.asi-icon {
  width: 35px;
  height: 35px;
}
.bg-historytablehead {
  background-color: #540326;
  color: #fff;
  text-align: justify;
}
.historytablebody {
  text-align: justify;
}
.historytablebody td {
  vertical-align: middle;
}
.color-green {
  color: #0e8859;
}
table .bg-historytablehead th:first-child {
  border-radius: 10px 0 0 10px;
}

table .bg-historytablehead th:last-child {
  border-radius: 0 10px 10px 0;
}
table .bg-historytablehead th {
  padding: 12px;
}
.admin-pro-img {
  border-radius: 62px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  padding: 5%;
}
.profile-head {
  position: relative;
  height: 28vh;
}
.profile-head1 {
  height: 15vh;
  background: linear-gradient(90deg, #df7239 0%, #e19937 100%);
  border-radius: 10px 10px 0px 0px;
}
.profile-head2 {
  position: absolute;
  top: 12vh;
  left: 3%;
}
.pro-management {
  background: #ffffff;
  border: 1px solid #e8ebed;
  border-radius: 6px;
  padding: 1%;
}
.color-orange {
  color: #ff8329;
}
.send-pro-bg {
  background-color: #f3f3f3;
  padding: 2%;
}
.pl-send-pro-bg {
  padding-left: 6%;
}
/* .main .login-btn {
  color: #ffffff;
  font-weight: 600;
  background-color: #540326;
  border: 8px solid #540326;
  font-size: 1rem;
} */
.color-red {
  color: #e73b3b;
}
.serviceTab {
  padding: 12px 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
}
.serviceTab.active {
  background-color: #540326;
  color: #ffffff;
  /*font-weight: 700;*/
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.serviceTab.inactive {
  background-color: #ffffff;
  color: #540326;
  /*font-weight: 700;*/
}
.admin-edit-icon {
  position: absolute;
  bottom: 8%;
  left: 35%;
  background-color: #ff9f43;
  padding: 5px 8px;
  font-size: 6pxx;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.admin-edit-icon span {
  font-size: 16px;
}

.toggle-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.toggle-switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}
.toggle-switch label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 5px;
  width: 19px;
  height: 19px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}
.toggle-switch input:checked + label {
  background: #540326;
}
.toggle-switch input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
.toggle-switch label:active:after {
  width: 30px;
}
.toggle-switch {
  display: flex;
  align-items: center;
}
.bg-grey {
  background: #f6f6f6;
  padding: 7%;
  border-radius: 8px;
}
.bg-grey th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  padding: 8px 0px;
}
.color-yellow {
  color: #ffbc2e;
  background: rgba(255, 202, 91, 0.16);
  border-radius: 16px;
  padding: 5px 12px;
  /*font-weight: 700;*/
}
.inprogress-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 5%;
  border-left: 7px solid #ffca5b;
}
.assigned-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 5%;
  border-left: 7px solid #5b79ff;
}
.color-green2 {
  color: #40bb82;
  background: rgba(64, 187, 130, 0.16);
  border-radius: 16px;
  padding: 5px 12px;
  /*font-weight: 700;*/
}

.color-blue {
  color: #7c7ef7;
  background: rgba(64, 187, 130, 0.16);
  border-radius: 16px;
  padding: 5px 12px;
  /*font-weight: 700;*/
}
.completed-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 5%;
  border-left: 7px solid #40bb82;
}
.color-redish {
  color: #ed1e3a;
  background: #ed1e3a21;
  border-radius: 16px;
  padding: 5px 12px;
  /*font-weight: 700;*/
}
.rejected-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 5%;
  border-left: 7px solid #ed1e3a;
}
.bg-grey .owl-theme .owl-nav {
  margin-top: 10px;
  text-align: end;
  -webkit-tap-highlight-color: transparent;
}
.bg-grey .owl-carousel .owl-nav button.owl-prev,
.bg-grey .owl-carousel .owl-nav button.owl-next,
.bg-grey .owl-carousel button.owl-dot {
  background: #540326;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 0px 12px 2px !important;
  font-size: 18px;
}
.table-icon-bg {
  background-color: #540326;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #ffffff;
}
.table-icon-bg.active {
  box-shadow: 0px 0px 4px #540326;
  color: #ffffff;
}
.service-package .Modal-View {
  width: 45%;
  /* height: 700px; */
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
  position: relative;
}
.form-check-input:checked {
  background-color: #e07c39;
  border-color: #ed6a00;
}
.form-check-input:focus {
  border-color: #f1c07d61;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f1c07d61;
}

.white-wrapper {
  background-color: #fff;
  border-radius: 4px;
}
/* steps */
.steps {
  margin-bottom: 40px;
}
.steps ul {
  display: flex;
  justify-content: space-between;
}
.steps ul li {
  width: 25%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  list-style-type: none;
}
.steps ul li::after {
  content: "";
  position: absolute;
  top: 14px;
  width: 100%;
  height: 1px;
  background-color: #dddfe4;
  margin-left: 0px;
}
.steps ul li:last-child::after {
  display: none;
}
.steps ul li label {
  background-color: #f1f1f1;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 12px;
  text-decoration: none;
}
.steps ul li span {
  font-size: 15px;
  /*font-weight: 400;*/
  text-decoration: none;
}
.steps ul li.active label {
  color: #fff;
  background: linear-gradient(180deg, #df7239 0%, #e19937 100%);
}
.steps ul li.active span {
  color: #df7239;
  font-weight: 500;
}
.steps ul li.active::after {
  background: linear-gradient(180deg, #df7239 0%, #e19937 100%);
}
.subh-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddfe4;
  padding-bottom: 6px;
  margin-bottom: 20px;
}
.white-wrapper .css-1fdsijx-ValueContainer {
  padding: 0.6rem 1rem;
}
.drop-heading {
  background-color: #f1ebec;
  color: #540326;
  border: 1px solid #54032600;
  /*font-weight: 700;*/
}
.drop-title {
  line-height: 1;
  padding: 12px;
  /*font-weight: 600;*/
  border: 1px solid #54032600;
  border-radius: 3px;
  margin-bottom: 0;
  color: #000000;
  cursor: pointer;
}
.drop-title:hover {
  background-color: #f1ebec;
  color: #000000;
  border: 1px solid #54032600;
}
.dropdown-box {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 10% 5%;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropdown-box:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.modal-drop-box {
  padding: 5%;
  height: 78vh;
  overflow-y: auto;
}
.drop-box-img {
  width: 50px;
  height: 50px;
}
.title-box {
  background-image: url("../Assets/Images/navbar-img/bg-drop.webp");
  background-size: auto;
  background-repeat: no-repeat;
  width: 100%;
  align-items: center;
  padding: 2px 20px 8px;
  margin: auto;
  display: flex;
  height: 80px;
  color: #fff;
}

.sidenav-logo {
  justify-content: center;
  display: flex;
}
.sidenav-logo .res-logo-img:before {
  display: none;
}
.sidenav .res-logo-img:hover {
  background-color: #54032600;
}
.sidenav .res-logo-img.active:before,
.sidenav .res-logo-img:hover:before {
  display: none;
}
.nav-modal-overflow {
  overflow-y: scroll;
  height: 75vh;
}
.audit-content-horiz ul li {
  display: inline-block;
  margin: auto 10px;
  font-size: 14px;
}
.audit-content-horiz ul {
  padding-left: 1rem;
}
.audit-content-horiz ul li:before {
  content: "• ";
}
.inline-audit {
  display: inline-block;
  margin: 10px;
}
.auditcontent-box .bg-grey {
  background: #f6f6f6;
  padding: 4%;
  border-radius: 8px;
}
.price-details-content {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;
  padding: 10px;
}
.price-details {
  /* background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0; */
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  right: 10px;

  z-index: 50;

  bottom: 65px;
  width: 250px;
}
.stick {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 5px;
}
.price-details .login-btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.create-package-scroll {
  height: 65vh;
  overflow-y: scroll;
}

.containerProperty {
  max-width: 1200px !important;
}

.blogcard1,
.blogcard2,
.blogcard3 {
  width: 100%;
  background-size: cover;
  height: 40vh;
  margin-bottom: 15%;
  border-radius: 16px 16px 0px 0px;
  cursor: pointer;
  position: relative;
}

.blogcardimg {
  height: 25vh;
}

.offcanvas {
  max-width: 75% !important;
}

.propertydetailimg {
  margin-bottom: 25px;
}

/* Property */

.propertydetailcard {
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 30px;
  margin: 20px;
}

.propertydetail {
  margin-top: 3%;
  margin-bottom: 3%;
  background-color: #f7f7f7;
  padding: 20px;
}

.propertydetailpoint {
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 6px 25px;
  text-align: center;
  cursor: pointer;
}

.propertydes,
.propertydes1,
.propertydes2,
.propertydes3 {
  font-size: 16px;
  color: #484848;
  /*font-weight: 700;*/
  line-height: 1.2;
  text-decoration: none;
}
.propertydes {
  margin-top: 5%;
  margin-bottom: 2%;
  text-decoration: none;
}
.blogcard {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 30px;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
}
.blogcardimg {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 5%;
  height: 30vh;
  text-decoration: none;
}

.blogcardtitle {
  font-size: 16px;
  color: #484848;
  /*font-weight: 700;*/
  line-height: 1.2;
  margin-bottom: 2.5%;
  text-decoration: none;
}

.propertyspan,
.propertyspan1,
.propertyspan2 {
  font-size: 14px;
  color: #777;
  line-height: 1.2;
  text-decoration: none;
}
.propertyspan {
  margin-left: 3%;
  text-decoration: none;
}
.blogcardbottom {
  border-top: 1px solid #ebebeb;
  margin-bottom: 5%;
  text-decoration: none;
}

.blogcardpara {
  font-size: 14px;
  color: #484848;
  line-height: 1.714;
  text-transform: capitalize;
  margin-bottom: 5%;
  text-decoration: none;
}
.propertycardalignspace {
  display: flex;
  text-decoration: none;
}
.projectrow {
  margin-top: 3%;
}
.logout_text {
  color: #540326;
}
.service-package .modal-content {
  min-height: 200px;
  background-color: #fffcfc;
  border-radius: 0;
  /* padding: 25px; */
  border: 0px solid #fff0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.reg-page-scroll {
  height: 70vh;
  overflow-y: scroll;
}
.reg-page-scroll-buy-now {
  height: 100vh;
  /* overflow-y: scroll; */
}
@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 64px;
    background-color: #540326;
    /* color: #fff; */
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    color: rgb(255 255 255) !important;
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
    color: rgb(255 255 255) !important;
  }
  .css-1ygcj2i-MuiTableCell-root {
    background-color: #f3f6f9 !important;
  }
  .css-1w1rijm-MuiButtonBase-root-MuiButton-root {
    font-weight: 800 !important;
  }
  .css-7hd3a8-MuiSwitch-root
    .MuiSwitch-switchBase.Mui-checked
    + .MuiSwitch-track {
    background-color: #e08138 !important;
  }
  .css-7hd3a8-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    color: #e08c38 !important;
  }
  .css-2ulfj5-MuiTypography-root {
    color: #fff;
  }
  .btn-primary {
    color: #fff;
    background-color: #540326;
    border-color: #540326;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #540326;
    border-color: #540326;
  }
  .data-s {
    font-size: 20px;
  }
}
.greenbox-bg:before {
  content: "";
  display: inline-block;
  vertical-align: revert;
  width: 13px;
  height: 13px;
  margin-right: 4px;
  border-radius: 7.5px;
  background-color: #69b6d5;
}
.yellowbox-bg:before {
  content: "";
  display: inline-block;
  vertical-align: revert;
  width: 13px;
  height: 13px;
  margin-right: 4px;
  border-radius: 7.5px;
  background-color: #df9638;
}
.redbox-bg:before {
  content: "";
  display: inline-block;
  vertical-align: revert;
  width: 13px;
  height: 13px;
  margin-right: 4px;
  border-radius: 7.5px;
  background-color: #df3838;
}
.violetbox-bg:before {
  content: "";
  display: inline-block;
  vertical-align: revert;
  width: 13px;
  height: 13px;
  margin-right: 4px;
  border-radius: 7.5px;
  background-color: #695eef;
}
.color-gblue {
  color: #11d1b7;
  /*font-weight: 700;*/
}
.sp-bg-chart {
  background-color: rgba(0, 0, 0, 0) fff;
}
.drop-admin-nav .dropdown-menu a {
  margin: 0px;
}
.drop-admin-nav ul li {
  padding-left: 0px;
}
.drop-admin-nav ul {
  padding-bottom: 10px;
}
.drop-admin-nav .dropdown-menu.show {
  width: 100%;
}
.modal-open.auditModalzindex .modal-backdrop {
  z-index: 2000 !important;
}

.homebananim.animate {
  /* Define your animation styles */
  /* This can be a CSS animation, transition, or any other animation method */
  width: 100px;
  height: 100px;
  background-color: red;
  animation: example 1s;
  z-index: 1;
  position: relative;
}
@keyframes example {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.homebanleftanim.animate {
  animation: 1s slide-right;
}
@keyframes slide-right {
  from {
    transform: translateY(30vh);
  }
  to {
    transform: translateY(0px);
  }
}
.btnposition {
  position: sticky;
  bottom: 0;
}
.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}
.price-details h6 {
  background: #540326;
  color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.price-details .d-flex .login-btn {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background-color: #c56838;
  border-color: #c56838;
  padding: 5px;
  font-size: 14px;
}
.admin-service .btn-admin {
  position: sticky;
  bottom: 0;
}
.srpage .admin-service2 .btn-admin {
  position: sticky;
  bottom: 0;
}
.srpage .admin-service2 {
  height: 38vh;
  overflow-y: scroll;
}

.my-float {
  margin-top: 17px;
}
.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 5px;
  right: 10px;
  background-color: #c16337;
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  color: white;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  z-index: 5000;
  transition: all 0.2s ease-in-out;
}
.float.visible {
  visibility: visible;
  opacity: 1;
}
.modal-left-img {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.os-nav-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.more-offer-section {
  position: fixed;
  bottom: 10px;
  left: 10px;
  /* background-color: #c16337; */
  background: linear-gradient(180deg, #7d0539 42.02%, #540326 81.18%);
  color: white;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  z-index: 50;
  padding: 8px;
  transition: all 0.2s ease-in-out;
}
.more-offer-section .btn {
  color: white;
}

@keyframes vertical-shaking {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.android-download-section {
  animation: vertical-shaking 2s infinite;
  position: fixed;
  bottom: 300px;
  left: 10px;
  /* background-color: #c16337; */
  /* background: linear-gradient(180deg, #7d0539 42.02%, #540326 81.18%); */
  /* border: solid 3px linear-gradient(180deg, #7d0539 42.02%, #540326 81.18%); */
  border-style: solid;
  background-color: white;
  border-color: #7d0539;
  color: white;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  z-index: 50;
  padding: 8px;
  transition: all 0.2s ease-in-out;
}
.android-download-section .btn {
  color: white;
}

.ios-download-section {
  animation: vertical-shaking 2s infinite;
  position: fixed;
  bottom: 200px;
  left: 10px;
  /* background-color: #c16337; */
  /* background: linear-gradient(180deg, #7d0539 42.02%, #540326 81.18%); */
  /* border: solid 3px linear-gradient(180deg, #7d0539 42.02%, #540326 81.18%); */
  border-style: solid;
  background-color: white;
  border-color: #7d0539;
  color: white;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  z-index: 50;
  padding: 8px;
  transition: all 0.2s ease-in-out;
}
.ios-download-section .btn {
  color: white;
}

.ser-content-height {
  height: 30vh;
  overflow-y: auto;
}
.emp-design-ser-req {
  /* border: 1px solid #540326;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
}
p {
  text-align: justify;
}
.modal-content .dropdown-box p {
  text-align: center;
}
.req-call-back-fixed {
  position: fixed;
  bottom: 60px;
  right: 10px;
  cursor: pointer;
  opacity: 1;
  z-index: 5000;
  transition: all 0.2s ease-in-out;
}
.more-offer-icon {
  position: absolute;
  top: -16px;
  right: -4px;
  font-size: 27px;
  color: #ffffff;
  background-color: #7d0539;
  border-radius: 25px;
  padding: 5px;
}
/* .btn-fixed-pop{
  position: fixed;
  top:0;

}           */

.fixed-btn-req-ser {
  position: fixed;
  bottom: 10px;
  right: 70px;
  z-index: 50;
}

.scroll-table {
  /* height: 66vh; */

  margin-left: 15px;

  overflow-x: auto;

  /* overflow-y: auto; */

  white-space: nowrap;
}

.scroll-table .tab-body td,
.scroll-table .tab-body th,
.scroll-table .tab-header th {
  text-align: center;
}

/* .table th {
  font-family: "IBMPlexSans-Bold";

  padding-top: 12px;

  padding-bottom: 12px;

  padding-right: 35px;

  padding-left: 35px;

  vertical-align: middle;
}

.table td {
  padding-top: 12px;

  padding-bottom: 12px;

  vertical-align: middle;

  font-family: "IBMPlexSans-SemiBold" !important;
} */

.displayflexstyle {
  display: flex;
  justify-content: space-between;
}
.selectoptioncss {
  width: 25%;
  border-radius: 10px;
  background-color: #540326;
  height: 40px;
  color: white;
  font-size: 16px;
  padding: 5px;
}
.admintitlebg {
  margin-top: 3%;
  padding: 15px;
  background: #540326;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}
.admintitle {
  color: #ffffff;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 0;
}
.cardtable {
  border: 1px solid #f4f4f4;
  box-shadow: 0px 5px 10px rgb(51 66 87 / 5%);
  border-radius: 10px;
  padding: 20px;
  margin-top: 3%;
  background: #fff;
}
.ordercard {
  background: #540326;
  border-radius: 10px;
  padding: 23px;
  min-height: 45px;
}
.cardsubtitle {
  margin-top: 3px;
  color: #fff;
  text-transform: capitalize;
  /*font-weight: 600;*/
  font-size: 14px;
  line-height: 17px;
}
.iconcolor {
  color: #fff;
  margin-right: 8%;
}
.dashboardborderline {
  border-bottom: 1px solid #dddfe4;
  margin-top: 3%;
  margin-bottom: 3%;
}
.numbercolor {
  color: #fff;
  font-size: 18px;
  /*font-weight: 700;*/
}
.admin-service-wheeler p {
  text-align: left;
  margin-bottom: 3px;
}
.completed {
  color: rgb(255, 127, 65);
}

.job-center {
  display: flex;
  justify-content: center;
}

.bg-color-green {
  background-color: #b2e6e6;
  border-radius: 15px;
  display: inline-block;
  padding: 2px 20px;
  font-size: 13px;
  /* line-height: 2; */
  color: #4d4d4d;
}

.bg-color-green:hover {
  background-color: #00abad;
  color: #fff;
}

.addLanguage-skills {
  border-radius: 26px;
  border-style: none;
  background: #e4e4e4;
  color: #000000de;
}
.scroll-offer-modal {
  height: 57vh;
  overflow-y: auto;
  margin-bottom: 10px;
}
.offer-head-sec {
  background-color: rgb(255 255 255);
  color: rgb(84, 3, 38);
  border-radius: 10px;
  /*font-weight: 700;*/
}
.buy-now-offer-btn {
  color: #ffffff;
  /*font-weight: 600;*/
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  border: 2px solid #540326;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
}
.buy-now-offer-btn:hover {
  color: #ffffff;
  background: #c56a37;
}
.admin-service-wheeler-buy-now .btn-holder-tc li {
  color: #fff;
}
.spl-offer-img {
  width: 150px;
  height: auto;
  position: absolute;
  right: 30px;
  top: -9px;
}
/* width */
.scroll-offer-modal::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroll-offer-modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scroll-offer-modal::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}

/* Handle on hover */
.scroll-offer-modal::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}
.payment-images {
  align-items: center;
  background-color: #540326;
  display: flex;
  /* font-family: Arial, sans-serif; */
  height: 100vh;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.order-confirm-page {
  background-color: #fff;
  padding: 65px;
  border-radius: 30px;
}
.timer-bg-content {
  text-align: center;
  font-weight: bold;
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  color: #fff;
  border-radius: 50%;
  padding: 20px;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.web-height-fix {
  height: 100vh !important;
  overflow-y: auto;
}
.overflow-pages table tbody,
.overflow-pages table td,
.overflow-pages table tfoot,
.overflow-pages table th,
.overflow-pages table thead,
.overflow-pages table tr {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  padding: 6px;
}
.overflow-pages table p {
  margin-bottom: 0px;
}
@media screen and (max-width: 778px) {
  .timer-bg-content {
    margin-top: 20px;
    width: 150px;
    height: 150px;
  }
  .order-confirm-page {
    margin: 10px;
    border-radius: 12px;
  }
  .web-height-fix {
    height: auto !important;
  }
}

/* 31 Aug Styles */
.reset-btn {
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  color: #fff;
  /*font-weight: 700;*/
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  border: 0;
  padding: 16px;
  /* font-family: Arial, sans-serif; */
}
.admin-info td,
tr,
th {
  /* font-family: Arial, sans-serif !important; */
}
.alignment {
  text-align: center;
  font-size: 22px;
  margin-top: 3px;
  background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modelsize {
  font-size: 10.5px;
}
.bannericon {
  width: 100%;
}
.markalign {
  text-align: center;
  margin-top: 13px;
  cursor: pointer;
}

.price-details-2 {
  background: #000000;
  border-radius: 0px 20px 0px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  left: 0;
  z-index: 1000;
  bottom: 0;
  width: max-content;
}
.price-details-content-2 {
  /* font-weight: 600;*/
  font-size: 12px;
  margin-bottom: 0;
  padding: 2px 8px;
  color: #fff;
}
.dropdown-menu.show {
  display: block;
  width: 100%;
  background-color: #fbf9fb;
}
.selected-dropdownbox{
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 10px 15px;
}
.dropdown-box-2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.drop-box-img-2 {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.selected-dropdownbox:hover,.selected-dropdownbox.active {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.modal-drop-box-2 {
  padding: 2%;
  height: 60vh;
  overflow-y: auto;
}
.modal-drop-box-3 {
  height: 50vh;
  overflow-y: auto;
}

/* width */
.ser-content-height::-webkit-scrollbar ,.modal-drop-box-2::-webkit-scrollbar ,.modal-drop-box-3::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.ser-content-height::-webkit-scrollbar-track ,.modal-drop-box-2::-webkit-scrollbar-track ,.modal-drop-box-3::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.ser-content-height::-webkit-scrollbar-thumb ,.modal-drop-box-2::-webkit-scrollbar-thumb ,.modal-drop-box-3::-webkit-scrollbar-thumb {
  background: #540326; 
  border-radius: 10px;
}

/* Handle on hover */
.ser-content-height::-webkit-scrollbar-thumb:hover ,.modal-drop-box-2::-webkit-scrollbar-thumb:hover ,.modal-drop-box-3::-webkit-scrollbar-thumb:hover {
  background: #540326; 
}
.dropdown-box div p{
  text-align: center;
}
.selected-dropdownbox .dropdown-box-2 p{
  text-align: left;
}

.owl-theme .client-section {
  position: relative;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
}

.owl-theme .client-section:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.owl-theme .owl-item.center .client-section {
  transform: perspective(500px) rotateY(0deg);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border: 2px solid #bf6338; /* Highlight current slide */
}

.owl-theme .client-section .client-img {
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.owl-theme .client-section:hover .client-img {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.number-counter {
  background-color: #f1ebec;
  padding: 3rem 0;
}

.counter-content {
  text-align: center;
  padding: 2rem 1rem;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.counter-content h1 {
  font-size: 2.5rem;
  margin: 0;
  color: #bf6338;
}

.counter-content h5 {
  margin-top: 0.5rem;
  color: #333;
  font-size: 1rem;
}

.counter-content:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.border-right {
  min-height: 15px;
  max-height: 150px;
  border-right: 2px solid #bf6338;
}

@media (max-width: 767px) {
  .border-right {
    border-right: none;
    border-bottom: 1px solid #bf6338;
    margin-bottom: 1rem;
  }
}
.property-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Added border to the table */
  border-radius: 5px; /* Added border-radius to soften edges */
}

.property-table th,
.property-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better spacing */
  text-align: left;
  min-width: 90px;
  text-align: center;
}

.property-table th {
  background: linear-gradient(180deg, #c56a38 0%, #de9338 100%);
  color: white;
}

.property-table tbody tr:nth-child(even) {
  /* background: linear-gradient(180deg, #c56a38 0%, #de9338 100%); */
}

.property-table tbody tr:hover {
  background: linear-gradient(180deg, #c56a38 0%, #de9338 100%);
  color: white;
}
.filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Adjust the gap as needed */
  margin-bottom: 10px;
}
.table-heading{
  color: white;
  text-align: center;
  background: linear-gradient(180deg, #c56a38 0%, #de9338 100%);
}