.auctionContainer {
    padding: 10px;
    /* background-color: #f5f5f5; */
  }
  
  .modalContent {
    width: 50%;
    margin: auto;
  }
  
  .closeIcon {
    margin-right: 30px;
    margin-top: 50px;
    text-shadow: 0px 3px 6px rgba(171, 167, 167, 0.44);
    cursor: pointer;
  }
  
  .card {
    border-radius: 10px;
  }
  
  .card .fw-bold {
    color: #333;
  }
  
  .container {
    padding: 20px;
  }
  