
.filterContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
}
.leadsContainer {
    /* padding: 1rem; */
    padding: 20px;
}
.leadsContainerSmallNav{
    padding: 20px;
}

.filterInput,
.filterDropdown {
    padding: 0.5rem;
    width: 100%;
    max-width: 200px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.suggestions {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    background-color: white;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
}

.suggestionItem {
    padding: 0.5rem;
    cursor: pointer;
}

.suggestionItem:hover {
    background-color: #f1f1f1;
}

.leadsTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.leadsTable th,
.leadsTable td {
    padding: 0.75rem;
    border: 1px solid #ccc;
    text-align: left;
}

.leadsTable th {
    background-color: #f5f5f5;
}

.leadsTable tr:hover {
    background-color: #f1f1f1;
}

.actionButton {
    margin-right: 0.5rem;
    padding: 0.5rem;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 0.25rem;
}

.actionButton:hover {
    background-color: #0056b3;
}