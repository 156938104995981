.propertyList {
    padding: 20px;
  }
  
  .propertyItem {
    /* display: flex; */
    /* flex-direction: column; */
    /* gap: 10px; */
    padding: 10px;
    margin: 5px;
    width: 275px;
    /* margin-bottom: 20px; */
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .propertyDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  
  .label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .value {
    font-weight: normal;
  }
  
  .viewMore {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .viewMore button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .viewMore button:hover {
    background-color: #0056b3;
  }
  