/* MyNavbar.module.css */

.navbarContainer {
    padding: 20px;
    margin-top: 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000; /* Ensure it stays above other content */
    background-color: #fff; /* Ensure background is white */
    border-bottom: 1px solid #ddd; /* Optional: Add a bottom border */
}

.logoImg {
    max-height: 50px; /* Adjust as needed */
}

.contactContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.contactLink {
    color: black;
    text-decoration: none;
    margin-left: 15px;
    display: flex;
    align-items: center;
    transition: color 0.3s;
}

.contactLink i {
    margin-right: 5px; /* Space between icon and text */
}

.contactLink:hover {
    color: #dc3545; /* Hover effect color */
}

.navLink {
    position: relative;
    cursor: pointer;
    color: black;
    font-size: 20px;
    text-decoration: none;
}

.navLink::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #dc3545;
    transition: all 0.7s ease;
    transform: translateX(-50%);
}

.navLink:hover::after {
    width: 100%;
    left: 0;
    transform: translateX(0); /* Reset transform to smoothly expand from center */
}


.navActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navButton {
    margin-right: 10px;
}

.scheduleButton {
    border: 2px solid hsl(0, 0%, 100%);
    background-color: #dc3545; /* Adjust as needed */
    color: white;
}

.divider {
    border-top: 1px solid #ddd;
    margin: 15px 0; /* Space above and below the divider */
}

.navDropdown {
    min-width: 200px;
    color: black !important;
}

.navDropdownItem {
    padding: 10px 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.navDropdownItem:hover {
    background-color: #dc3545; /* Change this to your desired hover background color */
    color: white; /* Change this to your desired hover text color */
}

.nav-link{
    color: black !important;
}
.dropdown-toggle{
    margin-top: 100px;
}