.leadInfoContainer {
    padding: 20px; 
    background-color: #f8f9fa;
    min-height: 100vh;
}

.leadInfo {
    max-width: 900px;
    margin: 0 auto;
}

.skeletonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.skeletonCard {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.infoGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    /* background: #ffffff; */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.infoItem {
    /* background: #f9f9f9; */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.infoItem p {
    margin: 0;
    font-size: 14px;
    color: #555;
}

.infoItem strong {
    color: #333;
}

.infoItem:nth-child(odd) {
    background: #fff;
}

.infoItem:nth-child(even) {
    background: #f1f1f1;
}

.infoItem:last-child {
    border-bottom: none;
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.editButton, .deleteButton {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
    color: #fff;
}

.editButton {
    background-color: #007bff; /* Blue for editing */
}

.deleteButton {
    background-color: #dc3545; /* Red for deleting */
}

@media (max-width: 768px) {
    .infoGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .infoGrid {
        grid-template-columns: 1fr;
    }
}
