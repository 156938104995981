.taskCommentComponent {
  /* border: 1px solid #ccc; */
  padding: 20px;
  max-width: 700px;
  margin-left:220px ;
}

.taskCommentComponentSmall{
  padding: 20px;
  max-width: 700px;
  margin-left:50px ;
}
.taskCommentComponent h3 {
  margin-bottom: 20px;
}

.commentList {
  margin-bottom: 20px;
}

.commentItem {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.commentHeader {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.commentUserImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.commentUserName {
  font-weight: bold;
  margin: 0;
}

.commentTimestamp {
  font-size: 0.9em;
  color: #777;
  margin: 0;
}

.commentText {
  margin: 10px 0;
}

.commentForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.commentFormInput {
  flex: 1;
  margin-bottom: 10px;
  height: 150px;
  border-radius: 10%;
}

.commentFormButton {
  align-self: flex-end;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.commentFormButton:hover {
  background-color: #0056b3;
}