/* FreelancerPartnerNav.module.css */

/* Styles for the side navigation */
.sideNav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    overflow: auto; /* Scrollable content */
    z-index: 1;
  }
  
  /* Styles for the short state of the sidebar */
  .short {
    width: 50px; /* Width of the sidebar when collapsed */
  }
  
  /* Styles for the show state of the sidebar */
  .show {
    width: 200px; /* Width of the sidebar when expanded */
  }
  
  /* Styles for the header containing the logo and toggle button */
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align items to the edges */
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  /* Styles for the toggle button */
  .toggleButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 2;
  }
  
  /* Styles for the toggle button icon */
  .toggleIcon {
    color: #c82333; /* Icon color */
  }
  
  /* Styles for the logo image */
  .logoImg {
    height: 40px;
  }
  
  /* Styles for the navigation links container */
  .navItems {
    display: flex;
    flex-direction: column;
    padding: 10px 0; /* Adjusted padding for better spacing */
  }
  
  /* Styles for the nav links */
  .navLink {
    display: flex;
    align-items: center;
    padding: 12px 16px; /* Adjusted padding for better spacing */
    color: black;
    border-radius: 4px; /* Rounded corners for the link */
    transition: background-color 0.2s, color 0.2s;
    font-size: 15px !important; /* Reduced font size */
    margin-bottom: 10px; /* Space between links */
    position: relative; /* For positioning the text on hover */
  }
  
  /* Add margin between links */
  .navLink:hover {
    background-color: #c82333; /* Darker red color on hover */
    color: #fff; /* Text color on hover */
  }
  
  .active {
    background-color: #c82333; /* Darker red color on hover */
    color: #fff; /* Text color on hover */
  }
  /* Styles for the nav icons */
  .navIcon {
    margin-right: 15px; /* Space between icon and text */
  }
  
  /* Styles for the nav text */
  .navText {
    display: inline;
  }
  
  /* Hide text initially in short state */
  .short .navText {
    display: none;
  }
  
  /* Show text on hover in short state */
  .short .navLink:hover .navText {
    display: inline;
    position: absolute;
    left: 50px; /* Position text next to the icon */
    white-space: nowrap; /* Prevent text from wrapping */
    background-color: #fff; /* Optional: background color for the text */
    padding: 5px; /* Optional: padding around the text */
    border-radius: 4px; /* Optional: rounded corners for the text */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: shadow for the text */
    z-index: 2; /* Ensure text is above other elements */
  }

/* Styles for the top navigation bar */
.topNav {
    display: flex;
    justify-content: flex-end; /* Align items to the right */
    align-items: center;
    background-color: #f8f9fa;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    height: 70px;
    z-index: 2;
  }
  
  .topNavBig {
    margin-left: 200px;
    width: calc(100% - 200px); /* Adjust width based on sidebar */
  }
  
  .topNavShort {
    margin-left: 50px;
    width: calc(100% - 50px); /* Adjust width based on sidebar */
  }
  
  .topNavIcons {
    display: flex;
    align-items: center;
  }
  
  .topNavNotificationIcon {
    cursor: pointer;
    font-size: 20px;
    transition: color 0.3s;
    margin-left: 20px; /* Space between icons */
    position: relative;
  }
  
  .notificationBadge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 10px;
  }
  
  .navDropdownTitle {
    color: black !important; /* Set name color to black */
    display: flex;
    align-items: center;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  /* Hide the default dropdown caret icon */
  .navDropdownToggle::after {
    display: none !important; /* Hides the default caret */
  }
  
  .caretIcon {
    margin-left: 5px; /* Space between text and caret icon */
    font-size: 14px; /* Size of the custom caret icon */
  }
  