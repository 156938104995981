
.service-card {
    transition: transform 0.3s;
}

.service-card:hover {
    transform: scale(1.05);
}

.underline {
    position: absolute;
    bottom: -5px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 14%;
    height: 7px;
    background-image: linear-gradient(to right, rgba(220, 53, 69, 1), rgba(226, 153, 50, 1), rgba(255, 255, 255, 0));
}

.underlineTesti{
    position: absolute;
    bottom: -5px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
    height: 7px;
    background-image: linear-gradient(to right, rgba(220, 53, 69, 1), rgba(226, 153, 50, 1), rgba(255, 255, 255, 0));
}
.testimonial-box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.testimonial-box:hover {
    transform: translateY(-10px);
}

.position-absolute {
    position: absolute;
}

.top-0 {
    top: 0;
}

.end-0 {
    right: 0;
}

.fixed-height {
    height: 100vh; /* Adjust this value as needed */
    overflow-y: auto;
}
.active-filter{
    color: white !important;
    background-color: #dc3545 !important;/*rgba(220, 53, 69, 1) !important;*/
}

.skeleton-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.skeleton-card {
    width: 100%;
    max-width: 400px;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.skeleton-header {
    width: 100%;
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
    animation: pulse 1s ease-in-out infinite alternate; 
}

.skeleton-title {
    width: 80%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: pulse 1s ease-in-out infinite alternate; 
}

.skeleton-subtitle {
    width: 60%;
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 12px;
    animation: pulse 1s ease-in-out infinite alternate; 
}

.skeleton-button {
    width: 100px;
    height: 40px;
    background-color:rgba(220, 53, 69, 1) !important;
    border-radius: 8px;
    margin-top: 10px;
    cursor: pointer;
    animation: pulse 1s ease-in-out infinite alternate; 
}
/* 

.skeleton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.skeleton-card {
    width: 100%;
    max-width: 400px;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out infinite alternate; 
    
} */

/* .skeleton-header,
.skeleton-title,
.skeleton-subtitle,
.skeleton-button {
    width: 100%;
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
    animation: pulse 1s ease-in-out infinite alternate; 
} */

@keyframes pulse {
    0% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}