/* Responsive css start*/
.sidenav .dashboard-logo {
  display: none !important;
}

.responsive-topbar {
  display: none !important;
}
.sidenav .closemenu-toggle {
  display: none;
}
.profilesection1-content {
  justify-content: center;
  display: flex;
}
.res-profilesection2 {
  display: none;
}
.res-percentage-content {
  display: none;
}

@media screen and (max-width: 1100px) {
  /* admin navbar */
  .sidenav {
    width: 100%;
    z-index: 1000;
    background-color: white;
  }
  .closemenu-toggle {
    color: #540326;
    font-size: 28px;
    padding: 20px;
    display: block !important;
  }
  /* .topbar {
    display: none;
  } */
  .admin .topbar {
    display: block;
    margin-left: 0%;
    top: 85px;
    width: 100%;
    position: relative;
    height: 12vh;
    z-index: 1000;
    background-color: #fff;
  }
  .responsive-topbar {
    display: block !important;
  }
  .responsive-topbar .dashboard-logo .logo-dashboard-img {
    width: 65%;
    padding: 12px 0px;
    height: auto;
  }
  .responsive-topbar .menu-icon {
    font-size: 35px;
    color: #000;
    font-weight: 500;
    vertical-align: middle;
  }
  .sidenav {
    display: none;
  }
  .sidenav.active {
    display: block !important;
    width: 100%;
    z-index: 1000000000;
    position: fixed;
    top: 0%;
    padding: 10px;
    height: 100%;
  }
  .sidenav-logo {
    display: flex;
    justify-content: space-between;
  }

  .navbar {
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    width: 100%;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  .home-nav .navbar-light .navbar-nav .nav-link.active,
  .home-nav .navbar-light .navbar-nav .nav-link:hover,
  .innerpages-nav .navbar-light .navbar-nav .nav-link.active,
  .innerpages-nav .navbar-light .navbar-nav .nav-link:hover {
    width: fit-content;
  }
  .innerpages-nav .navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0);
  }
  .home-nav .navbar-light .navbar-nav .nav-link,
  .innerpages-nav .navbar-light .navbar-nav .nav-link {
    margin: 6px 2px;
  }
  .sticky-nav .logo-img {
    width: 50px;
    height: 50px;
  }
  .home-banner {
    margin-top: 12%;
  }
  .sticky-nav {
    position: fixed;
    top: 0;
    z-index: 10000;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px #eeebeb;
  }
  .home-banner-content.container {
    margin: 5% auto;
  }
  .home-banner-content .banner-heading {
    font-size: 40px;
  }
  .border-right2 {
    border: none;
  }
  .innerpages-nav .navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0);
  }
  .innerpages-nav .sticky-nav .navbar-nav .nav-link {
    color: rgb(4, 4, 4);
  }
  .innerpages-nav .navbar-light .navbar-toggler-icon {
    background-color: #fff;
    border-radius: 3px;
    width: 1.7em;
    height: 1.7em;
  }
  /*modal start*/
  .Modal-View {
    width: 75%;
  }
  .modal-content {
    width: 100% !important;
  }
  .border-right {
    border-right: 4px solid #703a3e00;
  }
  /* modal end */
  .aboutpage-banner,
  .legalpage-banner,
  .servicespage-banner,
  .careerpage-banner,
  .contactpage-banner,
  .auctionpage-banner {
    height: 18vh;
    margin-top: 100px;
  }
  .head-banner-posi {
    bottom: 0;
    top: 35%;
  }
  .navbar-light .navbar-toggler {
    color: rgb(22 22 22 / 55%);
    background-color: #fff;
    border-color: rgb(129 126 126);
  }
  .responsive-topbar {
    display: block !important;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  .responsive-topbar .dashboard-logo .logo-dashboard-img {
    width: 65%;
    padding: 12px 0px;
    height: auto;
  }
  .responsive-topbar .menu-icon {
    font-size: 35px;
    color: #000;
    font-weight: 500;
    vertical-align: middle;
  }
  .main {
    left: 0%;
    font-size: 14px;
    margin: 0px;
    height: 100%;
    position: relative;
    width: 100%;
    top: 15vh;
    padding: 10px;
    overflow-x: scroll;
  }
  .dash-card {
    margin-bottom: 20px;
  }
  .admin-service {
    margin: 3% auto;
  }
  .serviceTab {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-left: 4px solid #e09737;
  }
  .modal-content {
    height: auto !important;
    min-height: max-content !important;
  }
}

/* mob responsive  */
@media screen and (max-width: 630px) {
  .navbar {
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    width: 100%;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  .home-nav .navbar-light .navbar-nav .nav-link.active,
  .home-nav .navbar-light .navbar-nav .nav-link:hover {
    width: fit-content;
  }
  .home-nav .navbar-light .navbar-nav .nav-link {
    margin: 6px 2px;
  }
  .sticky-nav .logo-img {
    width: 50px;
    height: 50px;
  }
  .home-banner {
    margin-top: 22%;
  }
  .home-banner-content.container {
    margin: 12% auto;
  }
  .topbar-bg {
    text-align: center;
  }
  .sticky-nav {
    position: fixed;
    top: 0;
    z-index: 10000;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px #eeebeb;
  }
  .home-banner-content .banner-heading {
    font-size: 40px;
  }
  .text h6 {
    font-size: 13px;
  }

  /*modal start*/
  .Modal-View {
    width: 100% !important;
  }
  .modal-content {
    width: 100% !important;
  }
  .border-right {
    border-right: 4px solid #703a3e00;
  }
  /* modal end */

  .counter-content {
    margin-bottom: 15%;
  }
  .links {
    margin-bottom: 8%;
  }
  .about-banner {
    background-image: none;
  }
  .client-left-section {
    padding: 2%;
  }
  .tab-section ul {
    flex-direction: column;
    padding-left: 0;
  }
  .LegalTab {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-left: 4px solid #540326;
  }
  .serviceTab.inactive {
    background-color: #edeaea;
  }
  .dash-card h5 {
    font-size: 14px;
  }
  .dash-icon {
    font-size: 60px;
  }
  .dash-icon-bg {
    border-radius: 6px;
    width: 60px;
  }
  .dash-card h1 {
    font-size: 35px;
  }
  h5 {
    font-size: 18px;
  }
  .login-btn {
    padding: 0.5rem 0.5rem;
    font-size: 14px;
  }
  .steps ul {
    padding-left: 0;
  }
  .steps ul li span {
    font-size: 14px;
  }
  .css-2ulfj5-MuiTypography-root {
    font-weight: 700 !important;
    font-size: 18px !important;
  }
  .profile-head2 {
    right: 3%;
    padding: 6px;
    background-color: #ffff;
    border-radius: 8px;
    border: 1px solid #e09037;
  }
  .main .dash-card {
    margin-bottom: 0px;
  }
  .more-offer-section {
    bottom: 5px;
  }
  .float {
    bottom: 5px;
    right: 15px;
  }
  .price-details {
    position: relative;
    right: auto;
    z-index: 1;
    bottom: auto;
    margin: auto;
    margin-top: 20px;
    width: 300px;
  }
  .drop-admin-nav .dropdown-menu.show {
    width: 95%;
  }
  .modal-content {
    height: 100vh !important;
    min-height: max-content !important;
    overflow-y: auto;
  }
  .alignmentmobile {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    background: linear-gradient(180deg, #c56a37 42.02%, #e19937 81.18%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .bannericonmobile{
    width: 160%;
  }
}
