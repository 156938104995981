/* List.module.css */
.container {
    /* padding: 1rem; */
    margin-left: 240px;
    margin-top: -30px;
    margin-right: 30px;
}
.containerSmallNav{
    margin-left: 100px;
    margin-top: -30px;
}