.sticky-top {
    z-index: 10;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 550px;
    overflow: auto;
}

.non-sticky{
    max-height: 550px;
    overflow: auto;
}

.request-callback-form h5 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
}

.request-callback-form .form-label {
    font-weight: bold;
}

.request-callback-form .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}
