/* CSS file for ServicesCount component styling */

.underlineCount {
    position: absolute;
    bottom: -5px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 28%;
    height: 7px;
    background-image: linear-gradient(to right, rgba(202, 105, 56, 1), rgba(226, 153, 50, 1), rgba(255, 255, 255, 0));
}

.service-card-count {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    background-color: #fff; /* Ensure the card has a background color */
}

.service-card-count:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-primary-count {
    background-color: #ca6938;
    border: none;
    margin-top: 10px;
}

.btn-primary-count:hover {
    background-color: #e29932;
}

.card-title-count {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.card-subtitle-count {
    font-size: 2rem;
    color: #555;
    margin-bottom: 20px;
}

.container-count {
    max-width: 1200px;
    margin: auto;
    padding: 0 15px; /* Add padding for responsiveness */
}

.h2-count {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
    text-transform: uppercase; /* Make text uppercase for emphasis */
}

.h2-count::after {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    background: #ca6938;
    margin: 10px auto 0;
}
