/* UpdatePasswordComponent.module.css */

.container {
    max-width: 800px;
    margin-left: 250px;
    margin-top: -30px;
}

.containerSmallNavBar {
    max-width: 800px;
    margin-left: 100px;
    margin-top: -30px;
}

h2 {
    margin-bottom: 20px;
    color: #b37e83;
}

.form {
    /* display: flex; */
    flex-direction: column;
    /* align-items: center; */
}

.formGroup {
    /* margin-bottom: 20px; */
    margin-top: 20px;
    width: 100%;
}

.inputContainer {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.input:focus {
    border-color: #343a40;
    box-shadow: 0 0 5px rgba(52, 58, 64, 0.5);
}

.placeholder {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    transition: all 0.3s;
    pointer-events: none;
    color: #aaa;
}

.input:focus + .placeholder,
.input:not(:placeholder-shown) + .placeholder {
    transform: translate(-10px, -34px) scale(0.75);
    color: #343a40;
    background-color: #f8f9fa;;
    padding: 0 5px;
    z-index: 1;
}


.errorInput {
    border-color: #dc3545;
}

.error {
    color: #dc3545;
    font-size: 12px;
    margin-top: 5px;
}

.updateButton {
    background-color: #dc3545;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.updateButton:hover {
    background-color: #23272b;
}
