.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .column {
    flex: 1;
    max-width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  
  .column img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .column img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 768px) {
    .column {
      max-width: 100%;
    }
  }
  