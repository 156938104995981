.filters {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  h4 {
    margin-bottom: 20px;
  }
  
  .filterItem {
    margin-bottom: 15px;
  }
  
  .filterItem label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .filterItem select, .filterItem input[type="range"] {
    width: 100%;
  }
  
  .filterItem span {
    display: block;
    text-align: right;
    margin-top: 5px;
  }
  