.leadsContainer {
    /* padding: 1rem; */
    margin-left: 240px;
    margin-top: -30px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.leadsContainerSmallNav{
    margin-left: 100px;
    margin-top: -30px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.form {
    display: flex;
    flex-direction: column;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
}
