
.popup-banner {
  background: url('./Assets/Images/become-partner.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  width: 100%;
  cursor: pointer;
}

.no-scroll {
  overflow: hidden !important;
}

.close-icon-btn {
  right: 5%;
  top: 6%;
  color: white !important;
  cursor: pointer;
}

@media screen and (max-width:900px) {
  .popup-banner {
    cursor: pointer;
    background: url('./Assets/Images/become-partner-mob.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 600px;
    width: 400px;
    object-fit: cover;
  }

  .close-icon-btn {
    right: 20%;
    top: 7%;
  }
}

/* Preloader */
.container-preloader {
  align-items: center;
  cursor: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 50000;
}

.container-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

/* Spinner Loading */
.container-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 6px solid rgba(0, 0, 0, 0.2);
  border-top-color: #4e2427;
  /* It is not in alphabetical order so that you do not overwrite it */
  height: 4em;
  margin: 0 auto 2em auto;
  width: 4em;
}

/* Loading text */
.container-preloader .animation-preloader .txt-loading {
  font: bold 30px 'Montserrat', sans-serif;
  text-align: center;
  user-select: none;
}

.container-preloader .animation-preloader .txt-loading .characters:before {
  animation: characters 4s infinite;
  color: #4e2427;
  content: attr(preloader-text);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: rotateY(-90deg);
}

.container-preloader .animation-preloader .txt-loading .characters {
  color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.container-preloader .animation-preloader .txt-loading .characters:nth-child(2):before {
  animation-delay: 0.2s;
}

.container-preloader .animation-preloader .txt-loading .characters:nth-child(3):before {
  animation-delay: 0.4s;
}

.container-preloader .animation-preloader .txt-loading .characters:nth-child(4):before {
  animation-delay: 0.6s;
}

.container-preloader .animation-preloader .txt-loading .characters:nth-child(5):before {
  animation-delay: 0.8s;
}

.container-preloader .animation-preloader .txt-loading .characters:nth-child(6):before {
  animation-delay: 1s;
}

.container-preloader .animation-preloader .txt-loading .characters:nth-child(7):before {
  animation-delay: 1.2s;
}

.container-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.container-preloader .loader-section.section-left {
  left: 0;
}

.container-preloader .loader-section.section-right {
  right: 0;
}

/* Fade effect on loading animation */
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

/* Curtain effect */
.loaded .loader-section.section-left {
  transform: translateX(-101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}

.loaded .loader-section.section-right {
  transform: translateX(101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}

/* Animation of the preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

/* Animation of letters loading from the preloader */
@keyframes characters {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

/* Laptop size back (laptop, tablet, cell phone) */
@media screen and (max-width: 767px) {

  /* Preloader */
  /* Spinner Loading */
  .container-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }

  /* Text Loading */
  .container-preloader .animation-preloader .txt-loading {
    font: bold 3.5em 'Montserrat', sans-serif;
  }
}

@media screen and (max-width: 500px) {

  /* Prelaoder */
  /* Spinner Loading */
  .container-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  /*Loading text */
  .container-preloader .animation-preloader .txt-loading {
    font: bold 2em 'Montserrat', sans-serif;
  }
}

.pack-mod-admin .text-head {
  color: black;
  font-size: 17px;
}

.pack-mod-admin .text-content {
  color: black;
}

.pack-mod-admin .img-section {
  width: 100%;
  height: auto;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

@media screen and (max-width:778px) {
  .pack-mod-admin .nav-modal-overflow {
    height: auto;
  }
}


/* ------------- New Version of CSS -------------  */
body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.navbar {
  margin-top: 0;
  top: 40px;
}

.navbar-brand {
  font-weight: 1000;
}

.nav-link {
  font-weight: 500;
  font-size: 20px !important;
}

.navbar-nav a {
  margin-right: 2px !important;
}

.contact-header {
  background-color: black;
  color: white;
  padding: 0.5rem 0;
}

.contact-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.contact-header .contact-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.contact-header .contact-info a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
}

.contact-header .contact-info a .fa {
  margin-right: 0.5rem;
  transform: scaleX(-1); /* Invert the icon */
}

.follow-us {
  display: flex;
  align-items: center;
}

.follow-us a {
  margin-left: 10px;
}

.contact-footer {
  display: none;
}

.nav .btn-outline-primary {
  margin: 20px;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.contact-header,
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.contact-header {
  top: 0;
}

/* Add padding to the top of the page to avoid content overlap */
body {
  padding-top: 120px; /* Adjust based on the total height of both headers */
}
/* Mega menu styles */
.mega-menu {
  display: none;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  overflow-y: auto;
}

.mega-menu.open {
  display: block;
}

.mega-menu h5 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.scrollable-column {
  min-height:500px;
  max-height:500px;
  overflow-y: auto;
}

.mega-menu .nav-link {
  color: #333;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
  font-size: 16px; /* Reduced font size */
  border: 1px solid transparent; /* Add border for hover effect */
  border-radius: 5px; /* Rounded corners */
}

.mega-menu .nav-link:hover {
  background-color: #f0f0f0;
  border-color: #ccc; /* Change border color on hover */
}

.mega-menu .nav-link.active {
  background-color: #007bff;
  color: white;
  border-color: transparent; /* Hide border for selected menu item */
}

.mega-menu .nav-link:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.mega-menu-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.level1-menu {
  font-size: 16px !important; 
  text-align: right;
}

.mega-menu .level2-title {
  font-size: 1.2rem !important;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  padding: 10px; /* Add padding for spacing */
  z-index: 1; 
  background-color: #f8f9fa;
}

.mega-menu .level2-menu {
  padding: 3px;
  border-bottom: 1px solid #ccc; /* Border only on the bottom */
  transition: background-color 0.3s;
  font-size: 0.9rem !important; /* Reduced font size */
  height: 54px; /* Fixed height */
  overflow-y: auto; /* Enable vertical scroll */
  display: flex;
  align-items: center;
  justify-content: center; /* Center-align text */
}

.mega-menu .level2-menu:hover {
  background-color: #f0f0f0;
}

.text-primary{
  color: #dc3545 !important;
}
.btn-primary{
  /* background-color: #d48336 !important; */
  background-color: #dc3545 !important;
  color: white !important;
}
.btn-primary:hover{
  color:#c82333 !important;
  background-color: white !important;
}

.level1-menu.active {
  background-color: #d48336;
  color: white; /* Change text color when active */
  border-radius: 5px; /* Rounded corners for active menu */
}

/* Additional styles for distinction between col-md-3 and col-md-9 */
.level1-container {
  background-color: #f8f9fa; /* Light grey background for distinction */
  border-right: 2px solid #d48336; /* Border to separate columns */
  padding: 10px;
}

.level2-container {
  background-color: #ffffff; /* White background */
  padding: 20px;
  border-left: 2px solid #d48336; /* Border to separate columns */
}

.level2-title {
  font-weight: bold;
  color: #333; /* Darker color for the title */
  margin-bottom: 1rem;
}

.level2-menu {
  padding: 10px;
  font-size: 0.8rem; /* Further reduced font size */
  text-align: center;
  transition: all 0.3s ease;
}

.level2-menu:hover {
  background-color: #e0e0e0; /* Lighter background on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow effect */
}



@media (max-width: 992px) {
  .navbar {
    margin-top: -80px;
  }

  .nav .btn-outline-primary,
  .contact-footer .btn {
    margin-left: 20px;
    margin-right: 20px;
  }

  .contact-header {
    display: none;
  }

  .contact-footer {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding: 0.5rem 0;
    text-align: center;
  }

  .contact-footer .d-flex {
    flex-direction: column;
  }

  .contact-footer a {
    display: block;
    margin-bottom: 0.5rem;
  }

  .contact-footer a span {
    margin-left: 0.5rem;
  }
  .mega-menu {
    top: 80px;
    padding: 10px;
  }

  .mega-menu h5 {
    font-size: 1.3rem;
  }

  .scrollable-column {
    max-height: 300px;
  }
}
