.typewriter {
    /* font-family: 'Courier New', Courier, monospace; */
    /* font-family: "Caveat", sans-serif; */
    white-space: nowrap;
    overflow: hidden;
    /* color: #ce713a; */
    border-right: 0.1em solid black; /* Adding a border to simulate the cursor */
    display: inline-block;
  }
  
  .typecursor {
    display: inline-block;
    /* background-color: black; */
    margin-left: 0.1em;
    animation: blink 0.7s steps(1) infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  